.purpose-container {
  padding: 5em 1em;
}

.leadership .card{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
}

.leadership .card:hover{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.leadership .card:hover a::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.leadership .card a {
  display: inline-block;
  position: relative;
  color: #434343;
}

.leadership .card a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #4EAD5B;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.top-container {
  padding-bottom: 0;
  padding-top: 0;
}

.offcanvas-header {
  justify-content: flex-end;
  align-items: baseline;
}

.quotes .card {
  border: none;
  justify-content: space-evenly;
}

.purpose-fade-in {
  animation: fade-in ease 3s;
}

@keyframes fade-in {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
