.header-fonts {
    font-weight: bold;
    color: #434343 !important;
}

.nav-item {
    margin-right: 40px;
}

.dropdown-menu {
    padding: 0;
    margin-top: 0 !important;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #434343;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #4EAD5B;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.active-link {
    border-bottom: 2px solid #4EAD5B;
    margin-bottom: -1px;
}

.dropdown-toggle::after {
    display: none;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.up-logo {
    transform: rotate(0deg);
    transition: transform .2s linear;
}

.down-logo {
    transform: rotate(180deg);
    transition: transform .2s linear;
}

.dropdown-item {
    padding: 10px
}