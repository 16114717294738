.VJL48qbQzWENTFAh1Knk {
  display: none !important;
}

.select__control:hover, .select__control:focus, .select__control:active {
  color: #212529;
  background-color: #fff;
  border-color: #a7d6ad !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(78 173 91 / 25%);
}