.carousel-indicators {
    top: 100%;
}

.carousel-indicators [data-bs-target] {
    background-color: #4EAD5B;
}

ul.intramark-icon-list {

}

@media (prefers-reduced-motion: no-preference) {
    .button {
        transition: opacity 1.5s ease, transform 1.5s ease;
    }
}

.button-transition {
    opacity: 1;
    transform: none;
}

/* Style the wave divider */
.wave-divider {
    width: 100vw; /* Full viewport width */
    overflow: hidden; /* Prevent overflow */
    margin: 0; /* Remove any margin */
    padding: 0; /* Remove any padding */
    position: relative; /* Ensure the wave stays in place */
    svg {
        display: block; /* Prevent inline spacing issues */
        width: 100%; /* Match parent width */
        max-width: 100%; /* Prevent exceeding viewport width */
        height: auto; /* Maintain aspect ratio */
    }
}


.wave-section {
    background-color: #f8f9fa; // Same background color as the wave
    padding-bottom: 50px; // Adjust padding as needed
}

.vertical-list {
    position: relative;
}

.line {
    position: absolute;
    left: 8px; /* Center the line with the dots */
    top: 6px;
    bottom: 6px;
    width: 2px;
    background: #4EAD5B; /* Line color */
}

.vertical-list-item {
    position: relative;
    display: flex;
    align-items: center; /* Align the text with the dots */
    margin-bottom: 40px; /* Spacing between items */
}

.dot {
    position: relative;
    left: 0; /* Align the dot with the line */
    width: 18px;
    min-width: 18px;
    height: 18px;
    background: #4EAD5B; /* Dot color */
    border-radius: 50%; /* Make the dot circular */
    z-index: 1; /* Place the dot above the line */
}

.text {
    padding-left: 20px; /* Space between the dot and the text */
    font-size: 16px;
    color: #333;
    line-height: 1.5;
}
