.icon-border-right {
  border-right: 3px solid #4EAD5B;
}

.rotated-text {
  transform: rotate(-90deg) !important;
  /* Safari */
  -webkit-transform: rotate(-90deg) !important;
  /* Firefox */
  -moz-transform: rotate(-90deg) !important;
  /* IE */
  -ms-transform: rotate(-90deg) !important;
  /* Opera */
  -o-transform: rotate(-90deg) !important;
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.rectangle-side-border {
  background: #4EAD5B;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.border-right-darkgray {
  border-right: 2px solid darkgray;
}

.border-top-darkgray {
  border-top: 2px solid darkgray;
}

.border-dark-gray {
  border: 2px solid darkgray;
}

.font-disabled {
  color: lightgrey;
  -webkit-transition: all 1s ease;
}

.font-normal {
  -webkit-transition: all 1s ease;
}

.font-white {
  color: white;
}