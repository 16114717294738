.footer-fonts {
  font-weight: bold;
  color: #434343 !important;
}

.copyright-font {
  font-size: 14px;
}


.footer {
  margin-top: auto;
  box-shadow: 0px -1px 1px rgba(50, 50, 50, 0.1);
}

@media (max-width: 550px) {
  .footer-container {
    justify-content: center !important;
  }
}

.active-link {
  border-bottom: 2px solid #4EAD5B;
  margin-bottom: -1px;
}