body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max-width-100 {
  max-width: 100% !important;
}

.height-50vh {
  height: 50vh;
}

.h-100vh {
  height: 100vh;
}

$primary: #4EAD5B;

.btn-primary,
.btn-secondary,
.btn-success,
.btn-warning,
.btn-danger {
  color: #ffffff !important;
}

.btn.btn-outline-primary:hover {
  color: $primary;
  background: none;
  border-color: #a7d6ad;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(78 173 91 / 25%);
}

.font-primary {
  color: $primary;
}

.font-bold {
  font-weight: bold !important;
}

h2:after {
  background: none repeat scroll 0 0 $primary;
  bottom: -5px;
  content: "";
  display: block;
  height: 3px;
  position: relative;
  width: 100px;
  border-radius: 5px;
}

h1 {
  font-weight: 600 !important;
}

h2 {
  font-weight: 600 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-block {
  width: 50%;
}

.font-xs {
  font-size: 10px;
}

.fa-2xs {
  font-size: 0.5em;
}

.link-text {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
}

.link-text:hover {
  color: #3e8a49;
}

.text-justify {
  text-align: justify;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.padding-left-100 {
  padding-left: 100px !important;
}

@import "~bootstrap/scss/bootstrap";

